const serviceData = [
  {
    id: 1,
    title: "City Transfer",
    icon: "ri-map-pin-2-line",
    desc: "No queues, no delays - we’ll get you to your destination quickly.",
  },

  {
    id: 2,
    title: "Whole  City Tour",
    icon: "ri-community-line",
    desc: "Want To avoid stress your driver will be waiting to meet you no matter what happens.",
  },

  {
    id: 3,
    title: "Unlimited Miles Car Rental",
    icon: "ri-roadster-line",
    desc: "Enjoy a high-quality ride experience at surprisingly low prices.",
  },

  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    desc: "Make your fast and customized online booking from anywhere in the world",
  },

  {
    id: 5,
    title: "Many Pickup Locations",
    icon: "ri-map-pin-line",
    desc: "For complete peace of mind we’ll take you directly to your locations.",
  },

  {
    id: 6,
    title: "Airport Transfer",
    icon: "ri-flight-takeoff-line",
    desc: "We offer wide ranges of hassle-free airport transfers.",
  },
];

export default serviceData;
