// import images from all-images/blog-img directory
import img01 from "../all-images/blog-img/blog-1.jpg";
import img02 from "../all-images/blog-img/blog-2.jpg";
import img03 from "../all-images/blog-img/blog-3.jpg";

const blogData = [
  {
    id: 1,
    title: "Makkah",
    author: "Mohammed",
    date: "12 Sept, 2023",
    time: "9pm",
    imgUrl: img01,
    description:
      "Makkah is more than just a city; it is a spiritual journey of the soul, a quest for enlightenment and connection with the Divine. Whether one is a first-time visitor or a seasoned pilgrim, the experience of Makkah leaves an indelible mark on the heart and soul, inspiring a lifelong commitment to faith, compassion, and service to humanity. As we bid farewell to this sacred city, may we carry with us the lessons learned and the blessings received, striving to embody the spirit of Makkah in our daily lives.",
  },

  {
    id: 2,
    title: "Madina",
    author: "Obaid",
    date: "12 Dec, 2023",
    time: "6pm",
    imgUrl: img02,
    description:
      "  A visit to Medina is not merely a journey of sightseeing, but a pilgrimage of the soul, a quest for spiritual enlightenment and connection with the Divine. As we bid farewell to this radiant city, may we carry with us the lessons learned and the blessings received, striving to embody the spirit of Medina in our daily lives. Join us on this transformative journey as we delve deeper into the sacred legacy of Medina and rediscover the timeless wisdom of Islam's prophetic heritage.",
   
  },

  {
    id: 3,
    title: "Riyadh",
    author: "Ikram",
    date: "21 Feb, 2024",
    time: "5pm",
    imgUrl: img03,
    description:
      " Riyadh is more than just a capital city; it is a dynamic oasis where tradition and modernity converge to create a unique tapestry of culture, commerce, and innovation. As we bid farewell to this captivating metropolis, may we carry with us the memories of its vibrant streets, rich heritage, and warm hospitality, and may we continue to celebrate Riyadh's dynamic spirit in all its glory.",
    
  },
];

export default blogData;
