import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/camry-package-item.css";
import img01 from "../assets/all-images/cars-img/nissan-offer.png";


const CamryCar = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center">CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Jeddah Airport to Makkah Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 250.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Jeddah Airport to Makkah Hotel using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Makkah Hotel to Jeddah Airport
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 200.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Makkah Hotel to Jeddah Airport using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Makkah Hotel to Madina Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 500.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route  Makkah Hotel to Madina Hotel? using CAMRY / SONATA"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>

          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Madina Airport to Madina Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 150.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Madina Airport to Madina Hotel using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                  Madina Hotel to Madina Airport
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 100.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Madina Hotel to Madina Airport using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Makkah <br />
                  Ziyarat
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 200.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Makkah Ziyarat using CAMRY / SONATA?"
                    using
                    Camry
                    Car
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Madina <br />
                  Ziarat
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 150.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Madina Ziarat using CAMRY / SONATA?"
                    using
                    Camry
                    Car
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                   Makkah Hotel to Taif and Return
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 400.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route  Makkah to Taif and Return using CAMRY / SONATA?"
                    using
                    Camry
                    Car
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                  Madina Hotel to Makkah Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 500.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Madina Hotel to Makkah Hotel using CAMRY / SONATA?"
                    using
                    Camry
                    Car
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                  Jeddah Airport to Madina Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 550.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Jeddah Airport to Madina Hotel using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                  Jeddah to Taif and Return
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 1000.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Jeddah to Taif and Return using CAMRY / SONATA?"
                    using
                    Camry
                    Car
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
          <Col lg="4" md="12" sm="12" className="mb-5">
            <div className="camry-package__item">
              <div className="camry-package__img--gmc">
                <img src={img01} alt="" className="w-100" />
              </div>

              <div className="camry-package__item-content mt-4">
                <h2 className="section__title text-center"> CAMRY / SONATA</h2>
                <h5 className="section__title text-center">
                  Jeddah Airport to Jeddah Hotel
                </h5>
                <h6 className="rent__price text-center mt-">
                  SAR 200.00 <span></span>
                </h6>
                <button className=" w-50 camry-package__item-btn camry-package__btn-rent">
                  <a href="tel:00966578189937">Call</a>
                </button>

                <button className=" w-50 camry-package__item-btn camry-package__btn-details">
                  <a
                    href="https://wa.me/+966578189937?text=Hello How can I help this route Jeddah Airport to Jeddah Hotel using CAMRY / SONATA?"
                    rel="ropener"
                  >
                    Whatsapp
                  </a>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CamryCar;
